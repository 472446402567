import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'button-cy',
  template: `
    <button mat-button [type]="type" [class]="addClass + ' cy__btn'" [matTooltip]="iconOnly ? label : ''" [matTooltipPosition]="tooltipPosition" (click)="action($event)" [disabled]="disabled">
      <i *ngIf="icon" [class]="icon"></i><span *ngIf="!iconOnly&&label">{{ label }}</span>
      <ng-content></ng-content>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ]
})
export class ButtonCapYeiComponent {

  @Input() addClass?:string;
  @Input() label!: string;
  @Input() icon?:string;
  @Input() iconOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: string = 'button';
  @Input() tooltipPosition: TooltipPosition = 'below';
  @Output() onPress = new EventEmitter<any>();


  constructor() {}

  action(event: MouseEvent) {
    this.onPress.emit(event);
  }
}
